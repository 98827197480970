<template>
  <div
    class="form-group"
    :class="[
      {'input-group': hasIcon},
      {'has-danger': error},
      {'focused': focused},
      {'input-group-alternative': alternative},
      {'has-label': label || $slots.label},
      {'has-success': valid === true},
      {'has-danger': valid === false}
    ]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend input-icons"
    @click="onClickIcon">
      <span class="input-group-text">
        <span class="icon-left">
          <slot name="addonLeft">
            <!-- check argon-component -->
            <!-- <i :class="'ni ni-calendar-grid-58'" /> -->
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.8333 3.33317H14.5964V1.6665H13.3333V3.33317H6.66667V1.6665H5.41667V3.33317H4.16667C3.72464 3.33317 3.30072 3.50877 2.98816 3.82133C2.67559 4.13389 2.5 4.55781 2.5 4.99984V16.6665C2.5 17.1085 2.67559 17.5325 2.98816 17.845C3.30072 18.1576 3.72464 18.3332 4.16667 18.3332H15.8333C16.2754 18.3332 16.6993 18.1576 17.0118 17.845C17.3244 17.5325 17.5 17.1085 17.5 16.6665V4.99984C17.5 4.55781 17.3244 4.13389 17.0118 3.82133C16.6993 3.50877 16.2754 3.33317 15.8333 3.33317ZM16.5625 16.7087C16.5625 17.0953 16.2491 17.4087 15.8625 17.4087H4.1375C3.7509 17.4087 3.4375 17.0953 3.4375 16.7087V8.33317H16.5625V16.7087ZM16.5625 7.38265H3.4375V4.99671C3.4375 4.61011 3.7509 4.29671 4.1375 4.29671H15.8625C16.2491 4.29671 16.5625 4.61011 16.5625 4.99671V7.38265Z" fill="#525252"/>
              <rect x="8.33398" y="10" width="3.33333" height="1.08333" fill="#525252"/>
            </svg>
          </slot>
        </span>
      </span>
    </div>
    <slot v-bind="slotData">
      <input
        :value="value"
        v-bind="$attrs"
        class="form-control"
        :class="[{'is-valid': valid === true}, {'is-invalid': valid === false}, inputClasses, {'ie': $isIE}]"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
        @keyup.enter="handlekeyupEnter"
        id="input_calender"
      >
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append input-icons"
    @click="onClickIcon">
      <span class="input-group-text">
        <span class="icon-right">
          <slot name="addonRight">
            <i :class="addonRightIcon" />
          </slot>
        </span>
      </span>
    </div>
    <slot name="infoBlock" />
    <slot name="helpBlock">
      <div v-if="error" class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    },
    keyupEnter:{
      type: Function,
      description: 'Keyup enter listener'
    }
  },
  data() {
    return {
      focused: false
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      }
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners
      }
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      )
    },
    handlekeyupEnter: function() {
      return this.keyupEnter ? this.keyupEnter : this.doNothing
    }
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value)
    },
    doNothing: function() {
    },
    onClickIcon: function(evt) {
      this.$el.querySelector('input').focus();
    }
  }
}
</script>
<style lang="scss" scoped>
:deep( .input-group-text.icon-left ) {
   border-right: none;
}
:deep( .input-group-text.icon-right  ) {
   border-left: none;
}
.input-icons:hover {
  cursor: pointer;
}
#input_calender {
  &.ie {
    transition: 0.1s !important;
  }
}
</style>
