<template>
  <div
    class="form-group"
    :class="[
      {'input-group': hasIcon},
      {'has-danger': error},
      {'focused': focused},
      {'input-group-alternative': alternative},
      {'has-label': label || $slots.label},
      {'has-success': valid === true},
      {'has-danger': valid === false}
    ]"
  >
    <slot name="label">
      <label v-if="label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend mr-0">
      <span class="input-group-text no-border-right">
        <slot name="addonLeft">
          <i :class="addonLeftIcon" />
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <input
        :value="value"
        v-bind="$attrs"
        class="form-control"
        :class="[{'is-valid': valid === true}, {'is-invalid': valid === false}, inputClasses]"
        aria-describedby="addon-right addon-left"
        v-on="listeners"
        @keyup.enter="handlekeyupEnter"
        :autocomplete="$attrs.autocomplete || 'on'"
      >
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text" @click="changeIconPassword">
        <slot name="addonRight">
          <!-- <i :class="addonRightIcon" /> -->
          <img :src="getIconRight" alt="">
        </slot>
      </span>
    </div>
    <slot name="infoBlock" />
    <slot name="helpBlock">
      <div v-if="error" class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}">
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterix *)'
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined
    },
    alternative: {
      type: Boolean,
      description: 'Whether input is of alternative layout'
    },
    label: {
      type: String,
      description: 'Input label (text before input)'
    },
    error: {
      type: String,
      description: 'Input error (below input)'
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes'
    },
    inputClasses: {
      type: String,
      description: 'Input css classes'
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon'
    },
    addonLeftIcon: {
      type: String,
      description: 'Addont left icon'
    },
    keyupEnter:{
      type: Function,
      description: 'Keyup enter listener'
    }
  },
  data() {
    return {
      focused: false,
      isPassword: true
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      }
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners
      }
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      )
    },
    handlekeyupEnter: function() {
      return this.keyupEnter ? this.keyupEnter : this.doNothing
    },
    getIconRight() {
      if (this.isPassword) {
        return require('~/assets/icons/icon_show.svg');
      }
      return require('~/assets/icons/icon_hide.svg');
    }
  },
  methods: {
    updateValue(evt) {
      const value = evt.target.value
      this.$emit('input', value)
    },
    onFocus(value) {
      this.focused = true
      this.$emit('focus', value)
    },
    onBlur(value) {
      this.focused = false
      this.$emit('blur', value)
    },
    doNothing: function() {
    },
    changeIconPassword(e) {
      this.isPassword = !this.isPassword;
      this.$emit('change-type-password');
    }
  }
}
</script>
<style>
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
  display: none !important;
}
input::-ms-clear {
  display: none;
}
input {
  filter: none;
}
.no-border-right {
  border-right-width: 0px !important;
}
</style>
